<template>
  <div class="pagination">
    <div class="row">
      <div
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-start
          col-sm-4 col-12
          table-pagination-text
        "
      >
        <span class="mb-sm-0 mb-2">
          Showing {{ 1 + (page - 1) * perPage }} to

          <template v-if="page == pageCount">{{ postCount }}</template>
          <template v-else>{{ page * perPage }}</template>

          of {{ postCount }} entries
        </span>
      </div>
      <div
        class="
          d-flex
          align-items-center
          justify-content-center justify-content-sm-end
          col-sm-8 col-12
        "
      >
        <ul class="pagination">
          <li
            class="prev"
            :class="{ disabled: page == 1 }"
            @click="changePage(page - 1)"
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 292 492"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M267.01 484.132L283.238 468.016C288.306 462.944 291.098 456.196 291.098 448.984C291.098 441.776 288.306 435.02 283.238 429.948L99.39 246.108L283.442 62.056C288.51 56.992 291.298 50.236 291.298 43.028C291.298 35.82 288.51 29.06 283.442 23.992L267.314 7.87197C256.826 -2.62403 239.742 -2.62403 229.254 7.87197L9.32999 227.008C4.26599 232.072 0.69797 238.82 0.69797 246.092L0.69797 246.176C0.697971 253.388 4.26999 260.136 9.32999 265.2L228.658 484.132C233.722 489.204 240.674 491.988 247.882 491.996C255.094 491.996 261.95 489.204 267.01 484.132Z"
                fill="black"
              />
            </svg>
          </li>
          <!--  -->
          <li class="content">
            <span>Page</span>

            <form @submit.prevent="changePage(inputPageNumber)">
              <input
                type="number"
                min="1"
                :max="pageCount"
                :value="page"
                @change="inputPageNumber = $event.currentTarget.value"
              />
            </form>

            <span>of {{ pageCount }}</span>
          </li>
          <!--  -->
          <li
            class="next"
            :class="{ disabled: page == pageCount }"
            @click="changePage(page + 1)"
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 292 492"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.986 7.86398L8.75798 23.98C3.68998 29.052 0.897983 35.8 0.897983 43.012C0.897983 50.22 3.68998 56.976 8.75798 62.048L192.606 245.888L8.554 429.94C3.486 435.004 0.697996 441.76 0.697996 448.968C0.697996 456.176 3.486 462.936 8.554 468.004L24.682 484.124C35.17 494.62 52.254 494.62 62.742 484.124L282.666 264.988C287.73 259.924 291.298 253.176 291.298 245.904L291.298 245.82C291.298 238.608 287.726 231.86 282.666 226.796L63.338 7.86398C58.274 2.79199 51.322 0.00796288 44.114 -3.24154e-05C36.902 -3.21001e-05 30.046 2.79199 24.986 7.86398Z"
                fill="black"
              />
            </svg>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  data() {
    return {
      inputPageNumber: this.page
    }
  },
  props: {
    pageCount: Number,
    postCount: Number,
    page: Number,
    perPage: Number,
  },
  methods: {
    changePage: function (pageNumber) {
      this.$emit("changePage", pageNumber);
    },
  },
};
</script>

<style lang="scss">
.pagination {
  &-text {
    span {
      font-size: 14px;
      color: #b9b9c3;
    }
  }

  ul {
    display: flex;
    li {
      border-radius: 5rem;

      &.content {
        display: flex;
        align-items: center;

        span {
          margin: 0 10px;
          cursor: default;
        }

        input[type="number"] {
          -moz-appearance: textfield;

          border-radius: 8px;
          border: 1px solid #d8d6de;
          padding: 6px 8px;
          transition: all 0.3s;

          &:focus {
            border-color:  #3f81e0;
          }

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }
      }

      &:not(.disabled) {
        cursor: pointer;
      }

      &:nth-child(2) {
        border-top-left-radius: 5rem !important;
        border-bottom-left-radius: 5rem !important;

        button {
          border-top-left-radius: 5rem !important;
          border-bottom-left-radius: 5rem !important;
        }
      }

      &:nth-last-child(2) {
        border-top-right-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important;

        button {
          border-top-right-radius: 5rem !important;
          border-bottom-right-radius: 5rem !important;
        }
      }

      &.active {
        background-color: #f3f2f7;
        border-radius: 0;

        button {
          border-radius: 5rem;
          background-color:  #3f81e0;
          color: #fff !important;
          font-weight: 600;
        }
      }

      &.prev {
        margin-right: 4px;
      }

      &.next {
        margin-left: 4px;
      }

      &.next,
      &.prev {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f2f7;
        border-radius: 50%;
        transition: all 0.3s;

        svg {
          path {
            transition: fill 0.3s;
          }
        }

        &.disabled {
          pointer-events: none;

          svg {
            path {
              fill: #b9b9c3;
            }
          }
        }

        &:not(.disabled) {
          &:hover {
            background-color:  #3f81e0;

            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f2f7;
        font-size: 14px;
        width: 32px;
        height: 32px;
        border: none;
        cursor: pointer;

        &:hover {
          color:  #3f81e0;
        }
      }
    }
  }
}
</style>