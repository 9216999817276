<template>
  <div class="track-container">
    <span class="range-value min">{{ minValue }} </span>
    <span class="range-value max">{{ maxValue }}</span>
    <div class="track" ref="_vpcTrack"></div>
    <div class="track-highlight" ref="trackHighlight"></div>
    <button
      :class="`track-btn track-l-${this.id}`"
      :ref="`track-l-${this.id}`"
    ></button>
    <button
      :class="`track-btn track-r-${this.id}`"
      :ref="`track-r-${this.id}`"
    ></button>
  </div>
</template>

<script>
export default {
  name: "PriceRangeSlider",

  props: {
    trackHeight: {
      type: Number,
      deafult: 1,
    },
    id: {
      type: String,
      default: (Math.random() + 1).toString(36).substring(7),
    },
    rangeStart: {
      type: Number,
      default: 1,
    },
    rangeEnd: {
      type: Number,
      default: 99,
    },
  },

  data() {
    return {
      min: this.rangeStart,
      max: this.rangeEnd,
      minValue: this.rangeStart,
      maxValue: this.rangeEnd,
      step: 1,
      totalSteps: 0,
      percentPerStep: 1,
      trackWidth: null,
      isDragging: false,
      pos: {
        curTrack: null,
      },
    };
  },

  methods: {
    moveTrack(track, ev) {
      let percentInPx = this.getPercentInPx();

      let trackX = Math.round(
        this.$refs._vpcTrack.getBoundingClientRect().left
      );
      let clientX = ev.clientX;
      let moveDiff = clientX - trackX;

      let moveInPct = moveDiff / percentInPx;

      if (moveInPct < 1 || moveInPct > 100) return;
      let value =
        Math.round(moveInPct / this.percentPerStep) * this.step + this.min;
      if (track === `track-l-${this.id}`) {
        if (value >= this.maxValue - this.step) return;
        this.minValue = value;
      }

      if (track === `track-r-${this.id}`) {
        if (value <= this.minValue + this.step) return;
        this.maxValue = value;
      }

      this.$refs[track].style.left = moveInPct + "%";
      this.setTrackHightlight();
    },
    mousedown(ev, track) {
      if (this.isDragging) return;
      this.isDragging = true;
      this.pos.curTrack = track;
    },

    touchstart(ev, track) {
      this.mousedown(ev, track);
    },
    /* eslint-disable */
    mouseup(ev, track) {
      this.$emit("rangeChanged", {
        minValue: this.minValue,
        maxValue: this.maxValue,
      });
      if (!this.isDragging) return;
      this.isDragging = false;
    },

    touchend(ev, track) {
      this.mouseup(ev, track);
    },

    mousemove(ev, track) {
      if (!this.isDragging) return;
      this.moveTrack(track, ev);
    },

    touchmove(ev, track) {
      this.mousemove(ev.changedTouches[0], track);
    },

    valueToPercent(value) {
      return ((value - this.min) / this.step) * this.percentPerStep;
    },

    setTrackHightlight() {
      this.$refs.trackHighlight.style.left =
        this.valueToPercent(this.minValue) + "%";
      this.$refs.trackHighlight.style.width =
        this.valueToPercent(this.maxValue) -
        this.valueToPercent(this.minValue) +
        "%";
    },

    getPercentInPx() {
      let trackWidth = this.$refs._vpcTrack.offsetWidth;
      let oneStepInPx = trackWidth / this.totalSteps;
      // 1 percent in px
      let percentInPx = oneStepInPx / this.percentPerStep;

      return percentInPx;
    },

    setClickMove(ev) {
      let track1Left = this.$refs.track1.getBoundingClientRect().left;
      let track2Left = this.$refs.track2.getBoundingClientRect().left;

      if (ev.clientX < track1Left) {
        this.moveTrack(`track-l-${this.id}`, ev);
      } else if (ev.clientX - track1Left < track2Left - ev.clientX) {
        this.moveTrack(`track-l-${this.id}`, ev);
      } else {
        this.moveTrack(`track-r-${this.id}`, ev);
      }
    },
  },

  mounted() {
    // calc per step value
    this.totalSteps = (this.max - this.min) / this.step;

    // percent the track button to be moved on each step
    this.percentPerStep = 100 / this.totalSteps;

    // set track1 initilal
    document.querySelector(`.track-l-${this.id}`).style.left =
      this.valueToPercent(this.minValue) + "%";
    // track2 initial position
    document.querySelector(`.track-r-${this.id}`).style.left =
      this.valueToPercent(this.maxValue) + "%";
    // set initila track highlight
    this.setTrackHightlight();

    var self = this;

    ["mouseup", "mousemove"].forEach((type) => {
      document.body.addEventListener(type, (ev) => {
        // ev.preventDefault();
        if (self.isDragging && self.pos.curTrack) {
          self[type](ev, self.pos.curTrack);
        }
      });
    });

    [
      "mousedown",
      "mouseup",
      "mousemove",
      "touchstart",
      "touchmove",
      "touchend",
    ].forEach((type) => {
      document
        .querySelector(`.track-l-${this.id}`)
        .addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, `track-l-${this.id}`);
        });

      document
        .querySelector(`.track-r-${this.id}`)
        .addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, `track-r-${this.id}`);
        });
    });

    // on track clik
    // determine direction based on click proximity
    // determine percent to move based on track.clientX - click.clientX
    document.querySelector(".track").addEventListener("click", function (ev) {
      ev.stopPropagation();
      self.setClickMove(ev);
    });

    document
      .querySelector(".track-highlight")
      .addEventListener("click", function (ev) {
        ev.stopPropagation();
        self.setClickMove(ev);
      });
  },
};
</script>

<style lang="scss">
.range-value {
  position: absolute;
  bottom: -26px;
  font-size: 14px;
}
.range-value.min {
  left: -4px;
}

.range-value.max {
  right: -8px;
}
.track-container {
  width: calc(100% - 14px);
  position: relative;
  cursor: pointer;
  height: 6px;
  margin-left: 7px;
}

.track,
.track-highlight {
  display: block;
  position: absolute;
  width: 100%;
  height: 6px;
  border-radius: 10px;
}

.track {
  background-color: #cdcdcd;
}

.track-highlight {
  background-color: #afc8ef;
  z-index: 2;
}

.track-btn {
  padding: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  top: calc(50%);
  transform: translateY(-50%);
  margin-left: -7px;
  border: none;
  background-color:  #3f81e0;
  touch-action: pan-x;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease;
  z-index: 2;
}
</style>
