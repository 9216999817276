<template>
  <div class="select" @click="selectToggle($event)">
    <div class="selected-option">
      <input
        type="text"
        id="select-search"
        v-model="activeOption"
        :placeholder="placeholder"
        :disabled="!search"
      />
    </div>
    <div class="select-actions">
      <button
        class="clear-selected"
        v-if="activeOption && !empty && search"
        @click="clearSelected()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14px"
          height="14px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-x"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
      <button class="select-toggle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15px"
          height="15px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-down open-indicator vs__open-indicator"
          role="presentation"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </button>
    </div>
    <ul class="select-list">
      <li
        class="select-list-item"
        v-for="(option, index) in selectOptions"
        :key="index"
        :data-value="option.value"
        @click="selectValue($event.currentTarget)"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Select",
  data() {
    return {
      activeOption: "",
    };
  },
  props: {
    placeholder: String,
    selectOptions: Array,
    empty: Boolean,
    defaultIndex: {
      type: Number,
      default: null
    },
    search: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectToggle: function (event) {
      event.currentTarget.classList.toggle("opened");
    },
    selectValue: function (element) {
      let elActive = document.querySelector(".select-list-item.active");

      if (elActive) elActive.classList.remove("active");

      element.classList.toggle("active");
      this.activeOption = element.innerText;
      this.$emit('optionSelected', element.dataset.value);
    },
    clearSelected: function () {
      this.activeOption = "";
      this.$emit('optionSelected', '');
      document
        .querySelector(".select-list-item.active")
        .classList.remove("active");
    },
  },
  created() {
    document.addEventListener("click", (event) => {
      let el = document.querySelector(".select.opened");

      if (el && el !== event.target && !el.contains(event.target)) {
        el.classList.remove("opened");
      }
    });
  },
  mounted(){
    if (this.defaultIndex != null) this.selectValue(document.querySelectorAll('.select-list-item')[this.defaultIndex])
  }
};
</script>

<style lang="scss">
.select {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 120px;
  height: 42px;
  padding-left: 6px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d8d6de;
  border-radius: 14px;
  transition: all .3s;

  &.opened {
    border-color:  #3f81e0;
    box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);

    ul.select-list {
      display: unset !important;
    }

    .select-toggle{
      transform: rotate(180deg)
    }
  }

  .selected-option {
    width: 100%;
    flex-wrap: nowrap;

    input[type="text"] {
      background: #fff;
      border: none;
      box-shadow: none;
      padding: 7px;
      cursor: pointer;
      max-width: 150px;

      &::placeholder {
        color: #565656;
      }
    }
  }

  .select-actions {
    display: flex;
    padding-right: 7px;

    button {
      display: flex;
      align-items: center;
      border: none;
      background: transparent;
      padding: 0;
      cursor: pointer;

      &.clear-selected {
        margin-right: 6px;
      }

      &.select-toggle{
        transition: transform .3s;
      }

      svg {
        color: #565656;
      }
    }
  }

  &-list {
    display: none;
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    top: 100%;
    left: 0;
    background: #fff;
    border-radius: 14px;
    margin-top: 15px;
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    z-index: 99;

    li {
      position: relative;
      color: #565656;
      padding: 12px 20px;
      cursor: pointer;
      overflow-x: auto;
      white-space: nowrap;

      &.active {
        background:  #3f81e0;
        color: #fff;

        &::after {
          content: "";
          width: 15px;
          height: 15px;
          background-image: url(../../assets/img/selected-item.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: 15px;
          display: inline-block;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &:first-of-type {
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
      }

      &:last-of-type {
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      &:hover:not(.active) {
        background-color: rgba( #3f81e0, 0.12);
        color:  #3f81e0;
      }
    }
  }
}
</style>